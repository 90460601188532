import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

// Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// Router & Store add
import router from './router'
// vue-resources
//import VueResource from 'vue-resource'
// vue-moment
//import VueMoment from 'vue-moment'
// store
import { store } from './store'

// vue-i18n
import { createI18n } from 'vue-i18n';
 
import { defaultLocale, localeOptions } from '@/constants/config'
// Notification Component Add
//import Notifications from '@/components/Common/Notification'
// Breadcrumb Component Add
import Breadcrumb from '@/components/Common/Breadcrumb'
// RefreshButton Component Add
import RefreshButton from '@/components/Common/RefreshButton'
// Colxx Component Add
import Colxx from '@/components/Common/Colxx'
// Perfect Scrollbar Add
// import vuePerfectScrollbar from 'vue-perfect-scrollbar'
import contentmenu from 'v-contextmenu'
//import lineClamp from 'vue-line-clamp'
import VCalendar from 'v-calendar'
//import 'v-calendar/lib/v-calendar.min.css'
import VueScrollTo from 'vue-scrollto'
import VueSignature from 'vue-signature-pad';

import mitt from 'mitt'

//v select
import vSelect from 'vue-select'
//SBA toggle
import SbaToggle from '@/components/Content/SbaToggle'

// vue date picker
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

//moment
import moment from 'moment';
// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faClock, faEnvelope, faPlusCircle, faMinusCircle, faSyncAlt, faSort, faFilter, faSearch, faTable, faPencilAlt, faTrashAlt, faPlusSquare, faTimesCircle, faExclamationCircle, faCheckCircle, faToggleOff, faEye, faEyeSlash, faToggleOn, faThumbsUp, faThumbsDown, faArrowRight, faArrowLeft, faUnlock, faMusic, faUserPlus, faUserTimes, faCheck, faTimes, faPaperPlane, faCopy, faQrcode, faClipboard, faSpinner, faTrash, faSave, faBarcode, faDownload, faCaretDown, faSquareCheck, faStar, faCodeBranch, faArchive, faFile, faChevronLeft, faChevronRight, faMoneyBill, faTicketAlt, faCircle, faSignature } from '@fortawesome/free-solid-svg-icons'
// swal
import VueSweetalert2 from 'vue-sweetalert2';

// sba-crud-table
import SbaCrudTable from 'sba-crud-table-v3';
// import SbaCrudTable from '@/components/SbaCrudTable/SbaCrudTable';

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

//jquery
import jQuery from 'jquery'

library.add(faUser, faClock, faEnvelope, faPlusCircle, faMinusCircle, faSyncAlt, faSort, faFilter, faSearch, faEye, faEyeSlash, faTable, faPencilAlt, faTrashAlt, faPlusSquare, faTimesCircle, faExclamationCircle, faCheckCircle, faToggleOff, faToggleOn, faThumbsUp, faThumbsDown, faArrowRight, faArrowLeft, faUnlock, faMusic, faUserPlus, faUserTimes, faCheck, faTimes, faPaperPlane, faCopy, faQrcode, faClipboard, faSpinner, faTrash, faSave, faBarcode, faDownload, faCaretDown, faSquareCheck, faStar, faCodeBranch, faArchive, faFile, faChevronRight, faChevronLeft, faMoneyBill, faTicketAlt, faCircle, faSignature)
app.component('font-awesome-icon', FontAwesomeIcon)

//general functionality
import { functions } from '@/mixins/functions.js';

// vue-signature-pad
app.use(VueSignature);



// vue-i18n
//app.use(VueI18n)

// vue-resources
//app.use(VueResource);

// vue-moment
app.use(moment);

// jQuery
app.use(jQuery);

// swal
app.use(VueSweetalert2);

app.use(mitt);

// SbaCrudTable
app.component('SbaCrudTable', SbaCrudTable);
app.component('SbaToggle', SbaToggle);
app.component('v-select', vSelect);
app.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
app.component('date-picker', DatePicker);

//var messages = { en: en,  nl: nl}
var messages = {}
//var messages = { }
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale

const i18n = createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages
});
app.use(i18n)

//get messages from backend
functions.methods.req('GET', '/public_controller/language/getForTranslation').then((response) => {
	if (response.status) {
		for (let locale in response.data) {
			i18n.global.setLocaleMessage(locale.toLowerCase(), response.data[locale]);
			
			i18n.global.missing = (locale, key) => {
				
				// check for uppercase
				if (i18n.global && /[A-Z]/.test(key)) {
					return i18n.global.t(key.toLowerCase());
				}
				/*
				functions.methods.req('POST', '/public_controller/language/insertMissingTranslation',
					{ language: locale, message: key, page: router.currentRoute.name },
					{
						headers : {
							'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
						}
					});
					*/
			}
		}
	} 
});

//app.use(Notifications)
app.component('piaf-breadcrumb', Breadcrumb)
app.component('b-refresh-button', RefreshButton)
app.component('b-colxx', Colxx)
// app.component('vue-perfect-scrollbar', vuePerfectScrollbar)
app.use(require('vue-shortkey'))
app.use(contentmenu)
//app.use(lineClamp, { /* plugin options */})
app.use(VCalendar, {
  firstDayOfWeek: 2, // ...other defaults,
  formats: {
    title: 'MMM YY',
    weekdays: 'WW',
    navMonths: 'MMMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L'
  },
  datePickerShowDayPopover: false,
  popoverExpanded: true,
  popoverDirection: 'bottom'
})
app.use(VueScrollTo)

/*
// http settings
app.http.options.xhr = { withCredentials: true };
app.http.interceptors.push((request, next) => {
    request.credentials = true;
    next((response) => {
		if (router.currentRoute.name !== 'login') {
			switch (true) {
				case response.body.message === 'messages.auth.failed' || response.body.message === 'messages.user.failed':
					router.push('/page/user/login');
					break;
				case response.body.message === 'messages.auth.expired' || response.body.message === 'messages.user.expired':
					router.push('/page/user/login');
					break;
			}
		}
	});
});
*/

app.mixin(functions);

/**** Revolution slider 
 * TODO:: Fix rev slide
//load revolution slider style files from backend
let revolutionSliderCSS = document.createElement("link")
revolutionSliderCSS.setAttribute("rel", "stylesheet")
revolutionSliderCSS.setAttribute("type", "text/css")
revolutionSliderCSS.setAttribute("href", process.env.VUE_APP_API_URL + '/revslider-standalone/assets/css/settings.css')
document.getElementsByTagName('head')[0].appendChild(revolutionSliderCSS);

let revJqueryScript = document.createElement('script')
revJqueryScript.setAttribute('src', "https://code.jquery.com/jquery-3.7.0.js")
document.getElementsByTagName('head')[0].appendChild(revJqueryScript);

let revSliderScripts = [
	"/revslider-standalone/assets/js/revslider.js",
	"/revslider-standalone/revslider/public/assets/js/jquery.themepunch.tools.min.js",
	"/revslider-standalone/revslider/public/assets/js/jquery.themepunch.revolution.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.layeranimation.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.kenburn.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.migration.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.carousel.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.parallax.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.slideanims.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.navigation.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.video.min.js",
	"/revslider-standalone/revslider/public/assets/js/extensions/revolution.extension.actions.min.js"
];

revSliderScripts.forEach((revSliderScriptUrl) => {
	let revSliderScript = document.createElement('script')
	revSliderScript.setAttribute('src', process.env.VUE_APP_API_URL + revSliderScriptUrl)
	document.getElementsByTagName('head')[0].appendChild(revSliderScript);
});
****/




app.use(router);

app.use(store);

app.mount('#app');

import { defaultColor } from '@/constants/config'
/*  if you want use single color theme

- ColorSwitcher component remove in 'src/App.vue' file.
- Remove multicolor section in current file
- Uncomment the two lines below

import "./assets/css/sass/themes/sba.light.orange.scss";
import "./main";
*/

/* if you want single color remove this section:multicolor */
var color = defaultColor

let render = () => {
    import('./assets/css/sass/themes/sba.' + color + '.scss').then(x => require('./main'))
}



render()
/* if you want single color remove this section:multicolor */

// Custom style
let customCss = document.createElement("link");
customCss.setAttribute("rel", "stylesheet");
customCss.setAttribute("type", "text/css");
customCss.setAttribute("href", process.env.VUE_APP_API_URL + '/design/getCss');
document.getElementsByTagName('body')[0].appendChild(customCss);

export default app;
