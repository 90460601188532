<template>
	
	<ul :ref="menu_item['id']" v-if="!mobileMenu">
		<li :style="getStyleBackground(subMenu)" :class="menu_item['subMenu'] && Array.isArray(menu_item['subMenu']) && menu_item['subMenu'][0] ? 'menu-item-has-children ' + subMenu.name.replace('.', '') : 'menu-item ' + subMenu.name.replace('.', '')" v-for="(subMenu, subMenuIndex) in menu_item['subMenu']" class="dropdown-menu-item" :key="subMenuIndex">
			<template v-if="subMenu['visible'] == 1" >
				<a @click="$router.push({path: '/' + subMenu.href}).catch(err => {});" v-if="subMenu.href" :style="getStyle(subMenu)" style="cursor:pointer;" class="menu-link">
					{{ $t(subMenu['name']) }}
					<svg v-if="subMenu && Array.isArray(subMenu['subMenu']) && subMenu['subMenu'].length > 0" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class=" menu-item-icon-sub pull-right svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
				</a>
				<!-- $router.push({path: '/' + subMenu.href}).catch(err => {}); -->
				<!-- repeating dropdown -->
				<ContentMenuDropDown :menu_item="subMenu"  />
			</template>
		</li>
	</ul>
	<ul v-else id="mobile-primary-menu" class="menu">
		<li v-for="(sub_menu, index) in menu_item['subMenu']" class="menu-item" :class="sub_menu.name.replace('.', '')" :style="getStyle(sub_menu)" :key="index">
			<template v-if="sub_menu['visible'] == 1">
				<a v-if="sub_menu['subMenu'] === undefined || sub_menu['subMenu'][0] === undefined && sub_menu.href" :style="getStyle(sub_menu)" @click="goToMenuItem(sub_menu.href)" data-toggle="collapse"> {{ $t(sub_menu['name']) }}</a>
				<a v-else :style="getStyle(sub_menu)" style="cursor:pointer;" class="link" @click="toggleSubMenu(index)">{{ $t(sub_menu['name']) }}
					<i class="pull-right" v-if="sub_menu['subMenu'] && Array.isArray(sub_menu['subMenu']) && sub_menu['subMenu'].length > 0" :class="sub_menu['showDropDown'] ? 'fas fa-minus-circle' : 'fas fa-plus-circle'"></i>
				</a>
				<h3 hidden>{{ sub_menu['name'] }}</h3>
				<meta name="keywords" :content="sub_menu.keywords">
				<ul id="mobile-primary-menu" class="menu" v-if="sub_menu['subMenu'] && Array.isArray(sub_menu['subMenu'])">
					<ContentMenuDropDown v-if="sub_menu['showDropDown']" :menu_item="sub_menu" />
				</ul>
			</template>
		</li>
	</ul>
</template>

<script>
import ContentMenuDropDown from '@/components/ContentMenuDropDown.vue'
import jQuery from 'jquery';

export default{
	name:'ContentMenuDropDown',
	props:['menu_item'],
	data(){
		return {
			pageType: 'dropdown-menu',
			mobileMenu: true,
		}
	},
	components:{
		ContentMenuDropDown,
	},
	methods:{
		goToMenuItem: function (href) {
			this.$router.push({ path: '/' + href }); 
			
			jQuery('#mobile-menu-01').collapse("toggle");
		},
		toggleSubMenu: function (index) {	
			if(this.menu_item['subMenu'][index]['showDropDown'] === undefined) {
				this.menu_item['subMenu'][index]['showDropDown'] = false;
			}
			this.menu_item['subMenu'][index]['showDropDown'] = !this.menu_item['subMenu'][index]['showDropDown'];
			this.$forceUpdate();
		},
	
		getStyleBackground: function (type) {
			if (jQuery(window).width() && jQuery(window).width() <= 1100) {
				if (type.mobile_background_color && type.mobile_background_color != '#FFFFFF' && type.mobile_background_color != 'null'){
					return "background-color: " + type.mobile_background_color + ";";
				} else {
					return "background-color: #007BF4; color:white;";
				}
			} else {
				if (type.background_color && type.background_color != '#FFFFFF' && type.background_color != 'null'){
					return "background-color: " + type.background_color + ";";
				} else {
					return "background-color: #007BF4; color:white;";
				}
			}
		},
		getStyle: function (type) {
			var css = "";
			var backgroundHoverCss = "";
			var textHoverCss = "";
			var style = "";

			if (jQuery(window).width() && jQuery(window).width() <= 1100) {
				if (type.mobile_background_hover) {
					//set background hover in css and concat to css variable
					backgroundHoverCss = '.' + type.name.replace('.', '') + ':hover, .' + type.name.replace('.', '') + ':hover > a{ background-color: ' + type.mobile_background_hover + ' !important}';
					css = css.concat(backgroundHoverCss);
				}
				if (type.mobile_text_hover) {
					//set text hover in css and concat to css variable
					textHoverCss = '.' + type.name.replace('.', '')  + ':hover, .' + type.name.replace('.', '') + ':hover > a{ color: ' + type.mobile_text_hover + ' !important}';
					css = css.concat(textHoverCss);
				}
				
				style = document.createElement('style');
				
				if (css != "") {
					if (style.styleSheet) {
						style.styleSheet.cssText = css;
					} else {
						style.appendChild(document.createTextNode(css));
					}
					document.getElementsByTagName('head')[0].appendChild(style);
				}
				
				style = "";
				
				if (type.mobile_background_color && type.mobile_background_color != '#FFFFFF' && type.mobile_background_color != 'null'){
					style = style.concat("background-color: " + type.mobile_background_color + ";");
				}
				
				if (type.mobile_text_color && type.mobile_text_color != 'null'){
					style = style.concat("color: " + type.mobile_text_color + ";");
				} else {	
					style = style.concat("color: white"); 
				}
				
				return style;
			} else {
				if (type.background_hover) {
					//set background hover in css and concat to css variable
					backgroundHoverCss = '.' + type.name.replace('.', '') + ':hover, .' + type.name.replace('.', '') + ':hover > a{ background-color: ' + type.background_hover + ' !important}';
					css = css.concat(backgroundHoverCss);
				}
				if (type.text_hover) {
					//set text hover in css and concat to css variable
					textHoverCss = '.' + type.name.replace('.', '')  + ':hover, .' + type.name.replace('.', '') + ':hover > a{ color: ' + type.text_hover + ' !important}';
					css = css.concat(textHoverCss);
				}
				
				style = document.createElement('style');
				
				if (css != "") {
					if (style.styleSheet) {
						style.styleSheet.cssText = css;
					} else {
						style.appendChild(document.createTextNode(css));
					}
					document.getElementsByTagName('head')[0].appendChild(style);
				}
				if (type.color && type.color != '#000000' && type.color != 'null'){
					return "color: " + type.color + ";";
				} else {	
					return "color: white"; 
				}
			}
			
		},
	},
	mounted() {
		if (jQuery(window).width() && jQuery(window).width() > 1100) {
			this.mobileMenu = false;
		}
		window.addEventListener('resize', function(event){
			if (jQuery(window).width() && jQuery(window).width() > 1100) {
				this.mobileMenu = false;
			} else {
				this.mobileMenu = true;
			}
		});
		
		//sort first on name
		this.menu_item['subMenu'].sort((a, b) => {
			let an = a.name.toLowerCase();
			let bn = b.name.toLowerCase();

			if (an < bn) {
				return -1;
			}
			if (an > bn) {
				return 1;
			}
			return 0;
		});
		
		//sort then on order
		this.menu_item['subMenu'].sort((a, b) => {
			if (a.order != 0) {
				return a.order - b.order;
			}
		});
		
		this.menu_item['subMenu'].sort()
	}
}
</script>