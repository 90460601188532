<template>
	<div id="app" class="h-100" v-if="isLanguageLoaded">
		<div v-show="loading" class="loading-page style1" ref="loading page">
			<div class="loading-page-wrapper">
				<div class="container">
					<h1 class="logo block">
						<img :src="getLogoUrl()" alt="Logo">
					</h1>
					<div class="loading-progress-bar block col-sm-10 col-md-9 col-lg-8">
						<div class="loading-progress" style="'width:' + percentage + '%;'"></div>
					</div>
					<span class="loading-text">Loading</span>
				</div>
			</div>
		</div>
		<div v-show="!loading" id="page-wrapper" class="h-100 d-flex-column">
			<header id="header" class="navbar-static-top flex-0" ref="header">
				<div class="topnav hidden-xs">
					<div class="container-fluid">
						<ul class="quick-menu float-end">
							<li v-show="user.id" id="topnav_login" class="logout-link">
								<a href="#" @click="logout()" class="link">{{ $t('logout.label') }}</a>
							</li>
							<li v-show="user.id" id="topnav_login" class="welcome-link">
								<router-link :to="{name: 'welcome'}"><span style="text-transform: initial;">({{ user.name }})</span></router-link>
							</li>
							<li v-show="!user.id" id="topnav_login" class="login-link">
								<router-link :to="{name: 'login'}">{{ $t('login.label') }}</router-link>
							</li>
							<li v-show="!user.id" id="topnav_login" class="register-link">
								<router-link :to="{name: 'register'}">{{ $t('register.label') }}</router-link>
							</li>
							<li class="ribbon language-toggle" v-if="enableTranslation">
								<a href="#">{{ $t(languages[$i18n.locale]) }} <font-awesome-icon icon="caret-down" /></a>
								<ul class="menu mini">
									<li v-for="(desc, lang) in languages" :key="lang" @click="changeLang(lang)" v-bind:class="{ active: true }"><a href="#" :title="desc">{{ $t(desc) }}</a></li>
								</ul>
							</li>
							<li id="topnav_login" class="contact-link">
								<router-link :to="{name: 'pagecontact'}">{{$t('contact.title')}}</router-link>
							</li>
							<li id="topnav_login" class="newsletter-link">
								<router-link :to="{name: 'newsletter'}">{{$t('newsletter.label')}}</router-link>
							</li>
						</ul>
					</div>
				</div>

				<div id="menu" class="main-header">
					<a href="#mobile-menu-01" data-toggle="collapse" data-target="#mobile-menu-01" class="mobile-menu-toggle" @click="collapseMobileMenu()" :style="getMenuButtonStyle(menu)">
						Mobile Menu Toggle
					</a>

					<div class="container-fluid d-flex">
						<h1 class="logo navbar-brand my-auto flex-grow-1">
							<a href="/">
								<div class="row">
									<div class="headerlogo col-auto">
										<img :src="getLogoUrl()" alt="Home" />
									</div>
									<div class="headertitle col align-self-center">
										<div>{{ $t("product.title") }}</div>
									</div>
								</div>
							</a>
						</h1>
						<div id="main-menu">
							<nav role="navigation">
								<ul class="menu">
									
									<template v-for="(menu_item, menu_item_index) in menu" :key="menu_item_index">
										<li :style="getStyle(menu_item)"  :class="menu_item['subMenu'] && Array.isArray(menu_item['subMenu']) && menu_item['subMenu'][0] ? 'menu-item-has-children ' + menu_item.name.replace('.', '') : 'menu-item ' + menu_item.name.replace('.', '')" v-if="menu_item['menu_item_type'] ===  'header'">
											<router-link :to="'/' + menu_item.href" v-if="menu_item.href" class="menu-link" :style="getStyle(menu_item)">
												{{ $t(menu_item['name']) }}
												
												<svg v-if="menu_item['subMenu'] && Array.isArray(menu_item['subMenu']) && menu_item['subMenu'].length > 0" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class=" menu-item-icon svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
											</router-link>
											<h3 hidden>{{ menu_item['name'] }}</h3>
											<meta name="keywords" :content="menu_item.keywords">
											<!-- set dropdown menu if possible -->
											<ContentMenuDropDown v-if="menu_item['subMenu']" :menu_item="menu_item" />
										</li>
									</template>
									<li class="menu-item" v-show="user.id">
										<router-link :to="{name: 'welcome'}">{{ $t('my.account.label') }}</router-link>
									</li>
									<li class="menu-item" v-if="packageId != 0">
										<router-link :to="{name: 'bookconfirm', params:{'packageId' : packageId}}"><i class="fas fa-shopping-cart"></i></router-link>
									</li>
								</ul>
							</nav>
						</div>
					</div>

					<nav id="mobile-menu-01" class="mobile-menu collapse">
						<ul id="mobile-primary-menu" class="menu">
							<li v-for="(menu_item, menu_item_index) in menu" class="menu-item" :style="getStyle(menu_item)" :key="menu_item_index">
								<template v-if="menu_item['menu_item_type'] ===  'header'">
									<a v-if="menu_item['subMenu'] === undefined || menu_item['subMenu'][0] === undefined && menu_item.href" :style="getStyle(menu_item)" @click="goToMenuItem(menu_item.href)">{{ $t(menu_item['name']) }}</a>
									<a v-else style="cursor:pointer;" class="link" @click="toggleSubMenu(menu_item)" :style="getStyle(menu_item)">{{ $t(menu_item['name']) }}
										<i class="pull-right" v-if="menu_item['subMenu'] && Array.isArray(menu_item['subMenu']) && menu_item['subMenu'].length > 0" :class="menu_item['showDropDown'] ? 'fas fa-minus-circle' : 'fas fa-plus-circle'"></i>
									</a>
									<h3 hidden>{{ menu_item['name'] }}</h3>
									<meta name="keywords" :content="menu_item.keywords">
									
									<ContentMenuDropDown v-if="menu_item['showDropDown'] && menu_item['subMenu'] && Array.isArray(menu_item['subMenu'])" :menu_item="menu_item" />
								</template>
							</li>

							<li class="menu-item">
								<router-link :to="{name: 'homepage'}">{{ $t('homepage.label') }}</router-link>
							</li>
							<li class="menu-item" v-show="user.id">
								<router-link :to="{name: 'welcome', params:{'packageId' : packageId}}">{{ $t('dashboard.label') }}</router-link>
							</li>
							<li class="menu-item" v-if="packageId != 0">
								<router-link class="link" :to="{name:'bookconfirm', params:{'packageId' : packageId}}" ><i class="fas fa-shopping-cart"></i></router-link>
							</li>
						</ul>

						<ul class="mobile-topnav container-fluid">
							<li v-show="!user.id">
								<!-- <router-link class="soap-popupbox" to="/login">{{ $t('login.label') }}</router-link> -->
								<a @click="$router.push({name: 'login'}).catch(err => {});" class="soap-popupbox" style="cursor:pointer;">{{ $t('login.label') }}</a>
							</li>
							<li v-show="!user.id">
								<!-- <router-link class="soap-popupbox" to="/register">{{ $t('register.label') }}</router-link> -->
								<a @click="$router.push({name: 'register'}).catch(err => {});" class="soap-popupbox" style="cursor:pointer;">{{ $t('register.label') }}</a>
							</li>
							<li v-show="user.id">
								<a @click="$router.push({name: 'dashboard'}).catch(err => {});">({{ user.name }})</a>
							</li>
							<li v-show="user.id">
								<a href="#" @click="logout()">{{ $t('logout.label') }}</a>
							</li>
							<li class="ribbon" v-if="enableTranslation">
								<a href="#" @click="showLangDropdown()">{{ $t(languages['en']) }} <font-awesome-icon icon="caret-down" /></a>
								<ul id="lang-dropdown" class="menu mini">
									<li v-for="(desc, lang) in languages" :key="lang" @click="changeLang(lang)" v-bind:class="{ active: true }"><a href="#" class="text-secondary" :title="desc">{{ $t(desc) }}</a></li>
								</ul>
							</li>
						</ul>
					</nav>
				</div>
			</header>

			<div class="page-title-container" ref="second-header" v-if="$route.name == 'page' || ( $route.matched && $route.matched[0] && $route.matched[0].name == 'page')">
				<div class="container-fluid">
					<div class="row">
						<div class="col page-title float-left">
							<h2 class="text-capitalize entry-title">{{ contentPageName && contentPageName.length ? $t(contentPageName) : '' }}</h2>
						</div>
						<ul class="col breadcrumbs">
							<li class="active  float-end">{{ contentPageName && contentPageName.length ? $t(contentPageName) : '' }}</li>
						</ul>
					</div>
				</div>
			</div>

			<router-view :user="user" :settings="settings"/>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="fixed-bottom"> 
					<cookieNotification v-model="cookies" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cookieNotification from '@/components/cookieNotification'
import ContentMenuDropDown from '@/components/ContentMenuDropDown.vue'
import jQuery from 'jquery';

export default {
	name: 'App',
	data () {
		return {
			cookies:[],
			user: {},
			contentPageName: '',
			languages: {
				'en': 'English',
				'nl': 'Nederlands',
			},
			subMenu: {
			},
			menu: [],
			packageId: 0,
			booking: {},
			settings: {},
			suppliers: [],
			loading: true,
			percentage: 0,
			enableTranslation: false,
			logoUrl:'',
			trackingConsent: false,
			key: 0,
			selectedLang: '',
		}
	},
	components: {
		cookieNotification,
		ContentMenuDropDown,
	},
	methods: {
		showLangDropdown: function () {
			let langDropdown = document.getElementById('lang-dropdown');
			langDropdown.classList.add('show');

		},
		collapseMobileMenu: function() {
			let mobileMenu = document.getElementById('mobile-menu-01');

			if (mobileMenu.classList.contains('collapse')) {
				mobileMenu.classList.remove('collapse');
			} else {
				mobileMenu.classList.add('collapse');
			}
		},

		goToMenuItem: function (href) {
			this.$router.push({ path: '/' + href }); 
			
			this.collapseMobileMenu();
		},
	
		getCartSession() {
			this.req('GET', '/public_controller/shoppingcart/getsession').then(response => {
				if (response.status) {
					this.booking = response.data.bookData;
					this.packageId = response.data.packageId;
				}
			})
		},

		getLanguages() {
			this.req('GET', '/public_controller/language/getLanguages').then(response => {
				if (response.status) {
					this.languages = response.data;
				}
			})
		},

		changeLang(lang) {
			this.$i18n.locale = 'en';
			//this.$moment.locale(lang);
		},

		getLogoUrl(){
			return process.env.VUE_APP_API_URL + '/public_controller/site_domain/getlogo';
		},

		logout () {
			this.req('GET', '/user/logout').then(response => {
				if (response.status) {
					this.user = {};
					this.$router.push('/login');
				}
			});
		},

		checkForValidation () {
			if (this.$route.query.token !== undefined && this.$route.query.token.length) {
				this.req(
					'GET', '/user/activatebytoken/' + this.$route.query.token,
				).then(response => {
					if(response.status){
						this.user = response.data;
						this.$swal(this.$t('success.label'), this.$t('messages.user.validated'), 'success');
					}else{
						this.$swal(this.$t('error.label'), this.$t(response.message), 'error');
					}
				});
			}
		},

		getMenus () {
			this.req('GET', '/public_controller/menu/getForCustomer').then(response => {
				this.menu = response.data;
				if (!Array.isArray(this.menu) || !this.menu[0] || !this.menu[0]['href'] || !this.menu[0]['name']) {
					// Error
					this.menu = [{
						href: "clubs",
						keywords: "clubs",
						name: "clubs.label",
					}, {
						href: "blogs",
						keywords: "blogs",
						name: "blogs.label",
					},{
						href: "reviews",
						keywords: "review",
						name: "review.label",
					},{
						href: "homepage",
						keywords: "homepage",
						name: "homepage.label",
					},];
				}
				//parse dropdown menu json
				this.menu.forEach((item,index) => {
					if (item.is_dropdown && item.dropdown_items){
						if (typeof item.dropdown_items == 'string') {
							try {
								item.dropdown_items = JSON.parse(item.dropdown_items);
							} catch (e) {
								item.dropdown_items = [];
							}
						}
						this.subMenu[index] = {open:true}
					}
				});

				
				this.loading = false;
			})
		},
		applyFont: function () {
			this.req('GET', '/public_controller/site_domain/getFont').then((response) => {
				if (response.status) {
					//json decode response
					if (response.data) {
                        try {
                            response.data = JSON.parse(response.data);
                        } catch(e) {
                            response.data = {};
                        }
                    } else {
                        response.data = {};
                    }
					//add font to head of site
					if (response.data.url && response.data.name) {
						//set link in head element
						let font = document.createElement('link');
						font.setAttribute('rel', 'stylesheet');
						font.setAttribute('href', response.data.url + '&display=swap');
						var head = document.getElementsByTagName("head")[0];
						head.appendChild(font);
						//set style in head element
						font = document.createElement('style');
						font.append('body, .p{font-family:'+ response.data.name +', sans-serif;}');
						head.appendChild(font);
					}

				}
			});
		},

		createZopim: function () {
			/*this.$http.get(process.env.API_URL + '/supplier/get/' + '8').then(response => {
				if (response.body.status) {
					var apiData = JSON.parse(response.body.data.api_data);
					//Zopim Chat Zendesk
					let zendeskchat = document.createElement('script');
					zendeskchat.id = 'ze-snippet';
					zendeskchat.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=' + apiData['api_key']);
					var head = document.getElementsByTagName("head")[0];
					head.appendChild(zendeskchat);
				}
			})*/
		},

		getSettings: function () {
			this.req('GET', '/public_controller/setting/getSettings').then((response) => {
				if (response.status) {
					this.settings = response.data;
					this.initiateCustomScript(this.settings);
					if (response.data['general'] && response.data['general']['enableTranslation']){
						this.enableTranslation = response.data['general']['enableTranslation'];
					}
					//set default language
					this.changeLang(response.data.language.code);
				}
			})
		},
		getSuppliers: function () {
			//get supplier with supplier type
			this.req('GET', '/public_controller/supplier/getfrontendapikeys').then((response) => {
				if (response.status){
					this.suppliers = response.data;
					if (this.trackingConsent) {

						//add google analytics
						this.addAnalytics();
						//add facebook pixel
						this.addFacebookPixel();
					}
				}
			})
		},
		checkTrackingConsent: function () {
			this.req('GET', '/public_controller/cookie/checkcookieconsent/trck_usr').then((response) => {
				if (response.status) {
					if (response.data) {
						this.trackingConsent = true;
						this.$forceUpdate;
					}
				}
			})
		},
		addAnalytics: function () {
		//get google analytics data
			if (this.suppliers) {
				if (this.suppliers['GOOGLEANALYTICS']){
					let analytics = this.suppliers['GOOGLEANALYTICS'];

					// json decode api data
					if (analytics){
						if (analytics.api_data) {
							analytics.api_data = this.jsonDecodeObject(analytics.api_data);
							// add google analytics to the site
							if (analytics.api_data.tracking_id && analytics.api_data.tracking_id.length > 0){
								let id = analytics.api_data.tracking_id;
								let analyticsScript = document.createElement("script")
								analyticsScript.setAttribute("type", "text/javascript")
								analyticsScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + id)
								document.getElementsByTagName('head')[0].appendChild(analyticsScript);

								window.dataLayer = window.dataLayer || [];
								//function gtag(){dataLayer.push(arguments);}
								this.gtag('js', new Date(), {cookie_flags: 'samesite:none;secure'});
								this.gtag('config', id, {cookie_flags: 'samesite:none;secure'});
							}
						}
					}
				}
			}
		},
		gtag: function() {
			window.dataLayer.push(arguments);
		},
		addFacebookPixel: function () {
			// get facebook pixel data
			if (this.suppliers) {
				if (this.suppliers['FACEBOOKPIXEL']){
					let pixel = this.suppliers['FACEBOOKPIXEL'];

					// json decode api data
					if (pixel !== undefined) {
						if (pixel.api_data) {
							pixel.api_data = this.jsonDecodeObject(pixel.api_data);

							// add facebook pixel to the site
							if (pixel.api_data.pixel_id && pixel.api_data.pixel_id.length > 0){
								let id = pixel.api_data.pixel_id;
								let pixelScript = document.createElement("script")
								pixelScript.append('!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document, \'script\', \'https://connect.facebook.net/en_us/fbevents.js\');fbq(\'init\', \''+ id +'\');fbq(\'track\', "PageView");');
								//add script to head
								document.getElementsByTagName('head')[0].appendChild(pixelScript);

								let pixelNoScript = document.createElement("noscript");
								let pixelImg = document.createElement("img");
								pixelImg.setAttribute("height", 1);
								pixelImg.setAttribute("width", 1);
								pixelImg.setAttribute("style", "display:none");
								pixelImg.setAttribute("src", "https://www.facebook.com/tr?id=" + id + "&ev=PageView&noscript=1");
								pixelNoScript.append(pixelImg);
								//add noscript to head
								document.getElementsByTagName('head')[0].appendChild(pixelNoScript);
							}
						}
					}
				}
			}
		},
		getMenuButtonStyle: function(menu) {
			if (menu[0] && menu[0]['mobile_background_color']) {
				return "background-color: " + menu[0]['mobile_background_color'] + ";";
			}
		},
		getStyle: function (type) {
			var style = "";
			if (jQuery(window).width() && jQuery(window).width() <= 1082) {
				if (type.mobile_text_color){ 
					style = style.concat("color: " + type.mobile_text_color + ";");
				}
				
				if (type.mobile_background_color){ 
					style = style.concat("background-color: " + type.mobile_background_color + ";");
				}
				
				return style;
			} else {
				var css = "";
				if (type.background_hover) {
					//set background hover in css and concat to css variable
					var backgroundHoverCss = '.' + type.name.replace('.', '') + ':hover, .' + type.name.replace('.', '') + ':hover > a{ background-color: ' + type.background_hover + ' !important}';
					css = css.concat(backgroundHoverCss);
				}
				if (type.text_hover) {
					//set text hover in css and concat to css variable
					var textHoverCss = '.' + type.name.replace('.', '')  + ':hover, .' + type.name.replace('.', '') + ':hover > a{ color: ' + type.text_hover + ' !important}';
					css = css.concat(textHoverCss);
				}
				
				style = document.createElement('style');
				
				if (css != "") {
					if (style.styleSheet) {
						style.styleSheet.cssText = css;
					} else {
						style.appendChild(document.createTextNode(css));
					}
					document.getElementsByTagName('head')[0].appendChild(style);
				}
				
				if (type.color && type.background_color){ 
					return "color: " + type.color + "; background-color: " + type.background_color + ";";
				}
			}
		},
		jsonDecodeObject: function (data) {
			if (data){
				try {
					data = JSON.parse(data);
				} catch (e) {
					data = {};
				}
			} else {
				data = {};
			}
			return data;
		},
		toggleSubMenu: function (menuItem) {
			if(menuItem['showDropDown'] === undefined) {
				menuItem['showDropDown'] = false;
			}
			menuItem['showDropDown'] = !menuItem['showDropDown'];
			this.$forceUpdate();
		},
		initiateCustomScript: function(settings) {
			if (typeof settings == 'object' && settings['scripts'] !== undefined 
				&& settings['scripts']['header'] !== undefined && settings['scripts']['header']) {
				jQuery('head').append(settings['scripts']['header']);
			}
			if (typeof settings == 'object' && settings['scripts'] !== undefined 
				&& settings['scripts']['footer'] !== undefined && settings['scripts']['footer']) {
				jQuery('body').append(settings['scripts']['footer']);
			}
		},
		onResize: function () {
			this.$forceUpdate();
		}
	},
	created () {
		this.checkTrackingConsent();
		this.getSettings();
		this.getSuppliers();
	},
	computed: {
		isLanguageLoaded: function() {
			let loaded = false;

			if (this.$i18n.availableLocales.length > 0 && this.$t != undefined) {
				loaded = true;
			}
			
			return loaded;
		}
	},
	mounted () {
		// Load styling
		jQuery('body').append('<link rel="stylesheet" type="text/css" href="' + process.env.VUE_APP_API_URL + '/public_controller/site_domain/getCSS">');
		jQuery('head').append('<link rel="shortcut icon" type="image/png" href="' + process.env.VUE_APP_API_URL + '/public_controller/site_domain/getFavicon">');
		jQuery('head').append('<link rel="stylesheet" href="' + process.env.VUE_APP_API_URL + '/public_controller/site_domain/getFont">');
		this.applyFont();
		// Load other
		this.getMenus();
		this.checkForValidation();
		this.createZopim();
		this.getCartSession();
		this.getLanguages();
		
		// Check if there is a user already logged in
		this.req('GET', '/user/check').then((response) => {
			if(response.status){
				this.user = response.data;
			}
		}, response => {
			this.$swal(
				{
					icon: 'error',
					title: 'API error',
					text: 'Unable to contact API',
					button: false,
					closeOnClickOutside: false,
				}
			);
		});
		this.percentage = 30;
		
		window.addEventListener('resize', this.onResize);
		
		/* Deprecated, dont know where this is used
		this.$on('refreshSettings', function () {
			this.getSettings();
		});
		
		this.$on('refreshComponent', function() {
			this.key += 1;
		});
		*/
	},
	updated () {
		if (this.$route.params.href){
			this.req('GET', '/public_controller/contentpage/getNameByHref/' + this.$route.params.href).then((response) => {
				if(response.status){
					this.contentPageName = response.data.name;
				}
			});
		}
	}
}
</script>


<style>
.swal-footer {
	text-align: center;
}
</style>
