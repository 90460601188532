import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/public/app',
		component: () => import('./views/public'),
		redirect: '/public/app/homepage',
		//beforeEnter: AuthRequired,
		children: [
			{
				name: 'homepage',
				path: 'public/app/homepage',
				alias: '/home',
				component: () => import(
					'./views/public/homepage'
				),
			}, {
				name: 'welcome',
				path: 'public/app/homepage',
				component: () => import(
					'./views/public/homepage' 
				),
			}, {
				name: 'pagecontact',
				path: 'public/app/contact',
				component: () => import(
					'./views/public/contact'
				),
			}, {
				name: 'newsletter',
				path: 'public/app/newsletter',
				component: () => import(
					'./views/public/newsletter'
				),
			},
		]
	}, {
		name: 'book',
		path: '/book/:packageId',
		component: () => import(
			'./views/public/book'
		),
	}, {
		name: 'bookconfirm',
		path: '/book/:packageId/confirm',
		component: () => import(
			'./views/public/book'
		),
		children: [
			{
				path: ':preBookId',
				name: 'paymentconfirm',
				component: () => import(
					'./views/public/book'
				),
			}
		],
	}, {
		name: 'App',
		path: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard',
		//beforeEnter: AuthRequired,
		children: [
			{
				path: 'page/app/dashboard',
				component: () => import(
					'./views/app/dashboard'
				),
			},
			{
				path: 'page/app/direct-debits',
				component: () => import(
					'./views/app/direct-debits'
				),
			},
			{
				path: 'page/app/invoicing/:runId',
				component: () => import(
					'./views/app/invoicing'
				),
			},
			{
				path: 'page/app/invoicing',
				component: () => import(
					'./views/app/invoicing'
				),
			},
			{
				path: 'page/app/administrator',
				component: () => import(
					'./views/app/administrator'
				),
			},
			{
				path: 'page/app/settings',
				component: () => import(
					'./views/app/settings'
				),
			},
		]
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('./views/app'),
		children: [
			{
				name: 'welcome',
				path: '',
				component: () => import(
					'./views/app/welcome'
				),
			}, {
				name: 'notifications',
				path: 'notifications',
				component: () => import(
					'./views/app/welcome'
				),
			}, {
				name: 'bookings',
				path: 'bookings',
				component: () => import(
					'./views/app/bookings'
				),
			}, {
				name: 'booking-details',
				path: 'booking/:id',
				component: () => import(
					'./views/app/booking-details'
				),
			}, {
				path: 'invoices',
				name: 'invoices',
				component: () => import(
					'./views/app/invoices'
				),
			}, {
				path: 'invoice/:id',
				name: 'invoice-details',
				component: () => import(
					'./views/app/invoice-details'
				),
			}, {
				path: 'quotations',
				name: 'quotations',
				component: () => import(
					'./views/app/quotations'
				),
			},  {
				path: 'quotation/:id',
				name: 'quotation-details',
				component: () => import(
					'./views/app/quotation-details'
				),
			}, {
				path: 'profile',
				name: 'profile',
				component: () => import(
					'./views/app/profile'
				),
				redirect: {name: 'profileInformation'},
				children: [
					{
						path: 'information',
						name: 'profileInformation',
						component: () => import(
							'./views/app/profile/ProfileInformation'
						),
					},
					{
						path: 'travellers',
						name: 'profileTravellers',
						component: () => import(
							'./views/app/profile/ProfileTravellers'
						),
					},
				]
			}, {
				path: 'products',
				name: 'products',
				component: () => import(
					'./views/app/products'
				),
			}, {
				path: 'product/:id',
				name: 'product-details',
				component: () => import(
					'./views/app/product-details'
				),
			}, {
				path: 'packages',
				name: 'packages',
				component: () => import(
					'./views/app/packages'
				),
			},  {
				path: 'package/:id',
				name: 'package-details',
				component: () => import(
					'./views/app/package-details'
				),
			}, {
				path: 'coupons',
				name: 'coupons',
				component: () => import(
					'./views/app/coupons'
				),
			}, {
				path: 'coupon/:id',
				name: 'coupon-details',
				component: () => import(
					'./views/app/coupon-details'
				),
			}, {
				path: 'content',
				name: 'content',
				component: () => import(
					'./views/app/content'
				),
				redirect: {name: 'contentHomepage'},
				children: [
					{
						path: 'homepage',
						name: 'contentHomepage',
						component: () => import(
							'./views/app/content/Homepage'
						),
					}, {
						path: 'category',
						name: 'category',
						component: () => import(
							'./views/app/content/Categories'
						),
					}, {
						path: 'pages',
						name: 'pages',
						component: () => import(
							'./views/app/content/Pages'
						),
						children: [
							{
								path: 'overview',
								name: 'pageOverview',
								component: () => import(
									'./views/app/content/Pages'
								),				
							}, {
								path: 'edit/:id/blog/:blogid',
								name: 'blogedit',
								component: () => import(
									'./views/app/welcome'
								),
							},
						], // end children of pages
					}, {
						path: 'pages/edit/:id',
						name: 'pageEdit',
						component: () => import(
							'./views/app/content/PageEdit'
						),
					},  {
						path: 'menuItems',
						name: 'menus',
						component: () => import(
							'./views/app/content/Menus'
						),
					}
				], // end children of content
			}, {
				path: 'socialmedia',
				name: 'socialmedia',
				component: () => import(
					'./views/app/socialmedia'
				),
			},  {
				path: 'email-templates',
				name: 'email-templates',
				component: () => import(
					'./views/app/email-templates'
				),
			}, {
				path: 'suppliers',
				name: 'suppliers',
				component: () => import(
					'./views/app/suppliers'
				),
			}, {
				path: 'supplier-group/:id',
				name: 'supplier-group-details',
				component: () => import(
					'./views/app/supplier-group-details'
				),
			}, {
				path: 'customers',
				name: 'customers',
				component: () => import(
					'./views/app/customers'
				),
			}, {
				path: 'customer/:id',
				name: 'customer-details',
				component: () => import(
					'./views/app/customer-details'
				),
			}, {
				path: 'customer-group/:id',
				name: 'customer-group-details',
				component: () => import(
					'./views/app/customer-group-details'
				),
			}, {
				path: 'traveldocuments',
				name: 'traveldocuments',
				component: () => import(
					'./views/app/travel-documents'
				),
			}, {
				path: 'traveldocuments-details/:id',
				name: 'travel-document-details',
				component: () => import(
					'./views/app/travel-document-details'
				),
			}, {
				path: 'settings',
				name: 'settings',
				component: () => import(
					'./views/app/settings'
				),
			}, {
				path: 'admin',
				name: 'admin',
				component: () => import(
					'./views/app/administrator'
				),
			}, {
				path: 'git',
				name: 'git',
				component: () => import(
					'./views/app/git'
				),
			},
		], // end children of dashboard
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ 
				name: 'login',
				path: 'login', 
				component: () => import('./views/public/user/Login')  
			},
			{ 
				name: 'register',
				path: 'register', 
				component: () => import('./views/public/user/Register') 
			},
			{ 
				name: 'forgot-password',
				path: 'forgot-password', 
				component: () => import('./views/public/user/ForgotPassword') 
			},
			{
				path: 'register/:token', 
				component: () => import('./views/public/user-register') 
			}
		],
	},
	{ path: '/login', redirect: '/page/user/login' },
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/welcome', redirect: '/dashboard/welcome' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{
		path: '/:reviews',
		name: 'page',
		component: () => import('./views/public/reviews'),
		props: true
	},
	{
		path: '/:href/:itemHref',
		name: 'pagecategory',
		component: () => import('./views/public/index'),
		children: [
			{
				path: '', 
				component: () => import('./views/public/content-page-view'), 
			},
		],
		props: true
	},
	{
		path: '/:href',
		name: 'page',
		component: () => import('./views/public/index'),
		children: [
			{
				path: '', 
				component: () => import('./views/public/content-page-view'), 
			},
		],
		props: true
	},
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
