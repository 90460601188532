<template>
	<div>
		<!-- simple cookie notification -->
		<div class="row" v-if="cookieConsentAdvancedView === false && showCookieConsent == true" style="z-index: 10;">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12">
						<div class="card card-body bg-light rounded-0">
							<div class="row">
								<div class="col-md-12">
									<div class="row">
										<!-- simple cookie information -->
										<div class="col-md-1 text-center">
											<img :src="getCookieImage()" alt="cookie" height="70" class="mt-2"/>
										</div>
										<div class="col-md-7">
											<h1>{{$t('cookies.header.label')}}</h1>
											<h3>{{$t('cookies.information.label')}}</h3> 
										</div>
										<div class="col-md-2 pb-2">
											<button type="button" class="btn btn-secondary-dark full-width mt-md-5" data-bs-toggle="modal" data-bs-target="#cookieModal">
												{{$t('cookies.showcookiesettings.label')}}
											</button>
										</div>
										<div class="col-md-2 pb-2">
											<button class="btn btn-success full-width mt-md-5" @click="setCookie()">
												{{$t('cookies.accept.button')}}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- Modal -->
		<div class="modal fade" id="cookieModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="z-index: 9999;">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title" id="exampleModalLongTitle">{{$t('cookies.header.label')}}</h1>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="row mb-3">
									<div class="col-12 col-md-6">
										<!-- functional cookies-->
										<div class="card card-body bg-white rounded-0 h-100">
											<h2 class="text-primary">{{$t('cookies.functional.label')}}</h2>	
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-2">
														<label class="switch mt-3">
															<input type="checkbox" checked disabled>
															<span class="slider round"></span>
														</label>
													</div>
													<div class="col-md-10">
														<h3>{{$t('cookies.functionaltext.label')}}</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
									<!-- analytical cookies-->
										<div class="card card-body bg-white rounded-0 mb-3 h-100">
											<h2 class='text-primary' >{{$t('cookies.analytical.label')}}</h2>		
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-2">
														<!-- Rounded switch -->
														<label class="switch mt-3">
															<input type="checkbox" :checked="cookies.analytical" @click="checkIfNeeded('analytical')">
															<span class="slider round"></span>
														</label>
													</div>
													<div class="col-md-10">
														<h3>{{$t('cookies.analyticaltext.label')}}</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col-12 col-md-6">
										<!-- tracking cookies-->
										<div class="card card-body bg-white rounded-0 mb-3 h-100">
											<h2 class='text-primary' >{{$t('cookies.tracking.label')}}</h2>		
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-2">
														<!-- Rounded switch -->
														<label class="switch mt-3">
															<input type="checkbox" :checked="cookies.tracking" @click="checkIfNeeded('tracking')">
															<span class="slider round"></span>
														</label>
													</div>
													<div class="col-md-10">
														<h3>{{$t('cookies.trackingtext.label')}}</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<!-- tracking cookies-->
										<div class="card card-body bg-white rounded-0 mb-3 h-100">
											<h2 class='text-primary' >{{$t('cookies.marketing.label')}}</h2>		
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-2">
														<!-- Rounded switch -->
														<label class="switch mt-3">
															<input type="checkbox" :checked="cookies.marketing" @click="checkIfNeeded('marketing')">
															<span class="slider round"></span>
														</label>
													</div>
													<div class="col-md-10">
														<h3>{{$t('cookies.marketingtext.label')}}</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						<button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="setCookie()">{{$t('cookies.save.button')}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'reviews',
	data () {
		return {
			showCookieConsent: false, // if true show cookie consent
			cookieConsentAdvancedView: false, // if false the simple cookie consent gets shown
			cookies:{
				name: 'consent',
				functional: true,
				analytical: true, 
				tracking: true, 
				marketing: true, 
			},
		}
	},
	
	methods: {
		
		//return cookie image
		getCookieImage: function(){
			return process.env.VUE_APP_API_URL + '/static/images/cookie.png';
		},
		
		//Checks the visitor's preferences on the cookies
		checkIfNeeded: function(name){
			this.cookies[name] = !this.cookies[name];
		},
	
		//Checks  
		checkIfCookieConsentIsAccepted: function(){
			this.req('GET', '/public_controller/cookie/checkCookieConsent/' + this.cookies.name ).then(response => {
				if (response.status){
					//check if the checkCookieConsent could find the consent cookie
					this.showCookieConsent = false;
				} else {
					this.showCookieConsent = true;
				}
			});
		},
	
		//Saves the review to the database.
		setCookie: function(){
			this.req(
				'POST',
				'/cookie/placeCookie',
				this.cookies
			).then(response => {
				if (response.status) {
					this.showCookieConsent = 'accepted';
					this.cookieConsentAdvancedView = 'accepted';
					//this.$swal('Success', this.$t('messages.set.cookie.success'), 'success');
				} else {
					this.$swal('Error', this.$t('messages.set.cookie.error'), 'error');
				}
			});
			
			let cookieModal = document.getElementById('cookieModal');
			cookieModal.classList.remove('show');
		},
	},
	
	created () {
		//if browser DNT is selected disable tracking analytical and marketing cookies
		if ((navigator.doNotTrack && navigator.doNotTrack == 1) || (window.doNotTrack && window.doNotTrack == 1)){
			this.cookies = {
				name: 'consent',
				functional: true,
				analytical: false, 
				tracking: false, 
				marketing: false, 
			}
			this.setCookie();
		}
	},
	mounted () {
		this.checkIfCookieConsentIsAccepted();
	}
}
</script>