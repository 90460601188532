<template>
	<div class="row form-group">
		<div class="col-md-12">
			<label v-if="label && label.length">{{ $t(label) }}</label>
			<small v-if="muted && muted.length" class="text-muted">{{ $t(muted) }}</small>
			<div class="input-group mb-3 cursor-pointer" @click="updateValue()">
				<input type="text" class="form-control" :value="modelValue == '1' ? $t('click.to.disable.label') : $t('click.to.enable.label')" disabled>
				<span class="input-group-text text-white" :class="[modelValue == '1' ? 'bg-success' : 'bg-danger']" id="basic-addon2">
					<label v-if="modelValue == '1'" class="cursor-pointer" style="margin-top: 0.2rem;"><i class="fa fa-check"></i> {{ $t('enabled.label') }} </label>
					<label class="cursor-pointer" style="margin-top: 0.2rem;" v-else><i class="fa fa-times" ></i> {{ $t('disabled.label') }} </label>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SbaToggle',
	props: {
		modelValue: Number,
		'label': {
			type: String,
		},
		'muted': {
			type: String,
			default: null,
		},
		'forceUpdate': {
			type: Boolean,
			default: false,
		}
	},
	emits: ['update:modelValue'],
	data () {
		return {
		}
	},
	methods: {
		updateValue: function (value) {
			let newValue = null;
			if (this.modelValue == '1') {
				newValue = '0';
			} else {
				newValue = '1';
			}
			
			this.$emit('update:modelValue', newValue);
		}
	}
}
</script>